/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  return localStorage.getItem("token");
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
  return localStorage.setItem("token", token);
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  return localStorage.removeItem("token");
}
