import Vue from "vue";
import Vuex from "vuex";
import { Notification, Message, MessageBox } from "element-ui";
Vue.use(Vuex);
import { getUserInfo, login } from "@/api/auth";
import { getToken, setToken, removeToken } from "@/utils/token";
export default new Vuex.Store({
  state: {
    token: getToken(),
    userName: null,
    Nickname: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setToken(token);
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setUserNickname(state, Nickname) {
      state.Nickname = Nickname;
    },
  },
  actions: {
    /**
     * @description 登录
     * @param {*} { commit }
     * @param {*} userInfo
     */
    async login({ commit }, userInfo) {
      const res = await login(userInfo);
      if (res.data.token) {
        if (res.data.user_detail.roles[0] != "doctor") {
          MessageBox.alert("只有医生角色才能登录该系统", {
            confirmButtonText: "确定",
          });
          throw "只有医生角色才能登录该系统";
        } else {
          commit("setToken", res.data.token);
          Notification({
            title: "Success",
            message: "欢迎登录",
            type: "success",
          });
        }
      } else {
        const err = `登录接口异常，未正确返回token...`;
        Message.error(err);
        throw err;
      }
    },

    //执行获取用户信息的方法
    async getUserInfo({ commit }) {
      const {
        data: { username, nickname },
      } = await getUserInfo();
      if (username) {
        // 如不使用username用户名,可删除以下代码
        if (username) commit("setUserName", username);
      }
      if (nickname) {
        // 如不使用nickname用户名,可删除以下代码
        if (nickname) commit("setUserNickname", nickname);
      }
    },
    resetAll({ commit }) {
      commit("setUserName", null);
      commit("setUserNickname", null);
      commit("setToken", null);
      removeToken();
    },
  },
});
