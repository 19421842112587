import Vue from "vue";
import VueRouter from "vue-router";
import VabProgress from "nprogress";
import store from "@/store";
import "nprogress/nprogress.css";
VabProgress.configure({
  easing: "ease",
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
});

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/index/index"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
  },
  {
    path: "/choose_patient",
    name: "ChoosePatient",
    component: () => import("@/views/choose_patient"),
  },
  {
    path: "/single_ans",
    name: "SingleAns",
    component: () => import("@/views/ans/single_ans"),
  },
  {
    path: "/group_ans",
    name: "GroupAns",
    component: () => import("@/views/ans/group_ans"),
  },
  {
    path: "/vas",
    name: "Vas",
    component: () => import("@/views/ans/vas"),
  },
  {
    path: "/inform",
    name: "Inform",
    component: () => import("@/views/inform/index"),
  },
  {
    path: "/report_detail",
    name: "ReportDetail",
    component: () => import("@/views/report/detail"),
  },
  {
    path: "/report_create",
    name: "ReportCreate",
    component: () => import("@/views/report/create"),
  },
  {
    path: "/report_summary",
    name: "ReportSummary",
    component: () => import("@/views/report/summary"),
  },
];

const router = new VueRouter({
  routes,
});
const routesWhiteList = ["/login", "/register", "/callback", "/404", "/403"];
router.beforeEach(async (to, from, next) => {
  VabProgress.start();
  if (to.path === "/login") {
    next();
  } else {
    let hasToken = store.state.token;
    if (hasToken) {
      let username = store.state.userName;
      if (!username) {
        store.dispatch("getUserInfo");
      }
      next();
    } else {
      if (routesWhiteList.includes(to.path)) {
        next();
      } else {
        next({ path: "/login", replace: true });
      }
    }
  }
});
router.afterEach(() => {
  if (VabProgress.status) VabProgress.done();
});

export default router;
